import React, { ReactElement } from 'react';
import { Container } from 'react-bootstrap';
import { Layout } from '../components/common';
import ContentImageRow from '../components/common/ContentImageRow';
import BannerSection from '../components/common/contentSections/BannerSection';
import ContentHero from '../components/common/contentSections/ContentHero';
import HeadingSection from '../components/common/contentSections/HeadingSection';
import VideoSection from '../components/common/contentSections/VideoSection';
import ImageContentRow from '../components/common/ImageContentRow';
import { ContentData } from '../utils/entities';

const MultiFactorContentData: ContentData[] = [
    {
        id: 1,
        title: `Advanced security`,
        desc: `Cancel out repeated login in credential input at every application sign in. Once verified and authenticated, customers can log into any application with StackIAM.`,
        imageUrl: `images/multifactor-advanced-security.svg`,
    },
    {
        id: 2,
        title: `Real time visibility`,
        desc: `Defend against data identity breach with SSO capabilities for custom applications`,
        imageUrl: `images/multifactor-realtime.svg`,
    },
];

const MultiFactor = (): ReactElement => (
    <Layout>
        <Container>
            <ContentHero
                heading="Adaptive Multi factor Authentication"
                desc="Step up your security and eliminate risks against stray password attack or phishing with Multi factor Authorization by using any of our authentication factor of your choice."
                buttonText="Get Started"
                imageUrl="/images/multifactor-hero@3x.png"
            />

            <HeadingSection
                heading="Features"
                subheading="Adaptive Multi factor Authentication"
            />
            {MultiFactorContentData.map((item) => {
                if (item.id % 2 === 0) {
                    return <ContentImageRow key={item.id} {...item} />;
                } else {
                    return <ImageContentRow key={item.id} {...item} />;
                }
            })}
            <VideoSection />
            <BannerSection />
        </Container>
    </Layout>
);

export default MultiFactor;
